<template>
  <div class="d-flex justify-content-center align-items-center loading" v-if="loading"><a-spin /></div>
  <div class="d-flex justify-content-start flex-wrap" v-if="loading === false" >
    <div class="item d-flex justify-content-start" v-for="item in items" :key="item.id">
      <div class="cover" style="width: 120px; height: 120px; margin-right: 10px; position: relative">
        <div style="position: absolute; right: 0; top: 0;">
          <a href="javascript:;" style="padding: 5px; border-bottom-left-radius: 5px; background: #fff;" @click="toggleFavorite(item);">
            <HeartOutlined v-if="isFavorited(item) === false" />
            <HeartFilled v-if="isFavorited(item)" />
          </a>
        </div>
        <img @click.self="$parent.openDetails(item)" src="../assets/img/placeholder_logo.png" v-if="item.images?.length === 0" style="object-fit: cover; width: 120px; height: 120px; border:none; padding: 8px;" />
        <img @click.self="$parent.openDetails(item)" :src="item.images[0]" v-if="item.images?.length > 0" style="object-fit: cover; width: 120px; height: 120px;" />
      </div>
      <div class="details" @click="$parent.openDetails(item)">
        <div class="name"><strong>Name: </strong> {{item.business_name}}</div>
        <div class="name"><strong>Type: </strong> {{item.type}}</div>
        <div class="location" v-if="item.address == null"><strong>Location: </strong>Online</div>
        <div class="location" v-else-if="item.address.hide === false"><strong>Location: </strong>{{item.address.address}}</div>
        <div class="location" v-else-if="item.address.hide === true"><strong>Location: </strong>{{item.address.city}}, {{item.address.state}}</div>
      </div>
    </div>
    <div v-if="items.length === 0">
      No result found!
    </div>
  </div>
</template>

<script>
import ASpin from 'ant-design-vue/lib/spin';
import { HeartOutlined, HeartFilled } from '@ant-design/icons-vue';
import 'ant-design-vue/lib/spin/style/index.css';
import $ from "jquery";

export default {
  components: {
    ASpin,
    HeartOutlined,
    HeartFilled
  },
  props: {
    loading: Boolean,
    items: Array
  },
  data() {
    return {
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }),
    }
  },
  methods: {
    formatPrice(value) {
      return this.formatter.format(value);
    },
    toggleFavorite(item) {
      if (this.$store.state.user == null) {
        $('#loginModal').modal('show');
        $('#myTab a[href="#sign-in-content"]').tab('show')
        return;
      }
      this.$store.dispatch('toggleFavoriteService', item.id);
    },
    isFavorited(item) {
      return this.$store.state.favorite_services.indexOf(item.id) > -1;
    }
  }
}
</script>

<style lang="scss" scoped>
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item {
    width: 48%;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    border: 1px solid #ddd;
    cursor: pointer;

    @media only screen and (max-width: 700px) {
      width: 100%;
      margin-right: 0;
    }
  }
</style>