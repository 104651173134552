<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container" style="margin-bottom: 40px;">
      <a-steps progress-dot :current="step" :initial="1">
        <a-step title="Step 1" description="Enter Listing Details" />
        <a-step title="Step 2" description="Confirmation" />
        <a-step title="Step 3" description="Complete" />
      </a-steps>
    </div>
    <div style="text-align: center; margin: 20px 0;" v-if="loading"><a-spin /></div>
    <div v-if="step === 1 && loading === false">
      <div class="container">
        <span style="display: block; margin-bottom: 16px;">
          Click here for great tips on creating an eye catching sales horse ad: <a href="https://youtu.be/vRYz8mcNBOM?si=wEUoe2xbEide-Gpu" target="_blank">https://youtu.be/vRYz8mcNBOM?si=wEUoe2xbEide-Gpu</a>
        </span>
        <span class="pricing-button">
          <router-link to="/plans" class="btn btn-primary" style="margin-right: 8px; font-style: normal;">View Pricing Plans</router-link>
          (Note: You will also see the Pricing Plan details before you reach the payment page.)
        </span>
        <hr data-v-566c3847="" data-v-060be8e0-s="" style="margin: 2rem 0px;">
        <div class="form-group">
          <label>Horse's Name</label>
          <a-input v-model:value="form.horse_name" />
        </div>
        <div class="form-group">
          <label>Horse or Pony</label>
          <div>
            <a-radio-group v-model:value="form.horse_or_pony">
              <a-radio-button value="HORSE">Horse</a-radio-button>
              <a-radio-button value="PONY">Pony</a-radio-button>
              <a-radio-button value="FOAL">Foal</a-radio-button>
            </a-radio-group>
          </div>
        </div>
        <div class="form-group">
          <label>Horse Location Address <span class="remarks-text" style="font-size: 0.9rem; color: gray;">(Please select from address list. You may simply enter a city and state. Or, enter address and click on Do Not Show Address button if you do not want your address to show on listing.)</span></label>
          <input type="text" id="autocomplete_search" class="form-control" :disabled="mapAutoComplete === null" :placeholder="mapAutoComplete === null ? 'Loading...' : 'Enter a location'" :value="this.form.address.address" />
        </div>
        <div class="form-group">
            <a-checkbox v-model:checked="form.address.hide">Do not show my street address.</a-checkbox>
        </div>
        <div class="form-group">
          <label>Discipline and Attributes <span class="remarks-text" style="font-size: 0.9rem; color: gray;">(Select up to 5 disciplines/attributes)</span></label>
          <div>
            <a-select v-model:value="form.discipline" mode="multiple" style="width: 100%" :virtual="false">
              <a-select-option
                v-for="discipline in disciplines"
                :value="discipline.id"
                :key="discipline.id"
                :disabled="form.discipline.length >= 5 ? (form.discipline.includes(discipline.id) ? false : true) : false"
              >{{discipline.name}}</a-select-option>
            </a-select>
          </div>
          <label style="margin: 0.5rem 0 0;"><span class="remarks-text" style="font-size: 0.9rem; color: gray;">** Questions or need us to add to the list? Email us at <a href="mailto:support@manestreetmarket.com">support@manestreetmarket.com</a> - we are happy to help!</span></label>
        </div>
        <div class="form-group">
          <label>Breed</label>
          <div>
            <a-select v-model:value="form.breed" mode="multiple" style="width: 100%" :max-tag-count="3" :virtual="false">
              <a-select-option :value="breed.id" :key="breed.id" v-for="breed in breeds">{{breed.name}}</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="form-group">
          <label>Age</label>
          <div>
            <a-select v-model:value="form.age" style="width: 100%" :virtual="false">
              <a-select-option disabled>Please select an option</a-select-option>
              <a-select-option value="0 UTERO">InUtero</a-select-option>
              <a-select-option value="0 UTERO">Utero</a-select-option>
              <a-select-option value="3 MONTH">3 months</a-select-option>
              <a-select-option value="6 MONTH">6 months</a-select-option>
              <a-select-option value="9 MONTH">9 months</a-select-option>
              <a-select-option value="1 YEAR">1 year</a-select-option>
              <a-select-option value="2 YEAR">2 years</a-select-option>
              <a-select-option value="3 YEAR">3 years</a-select-option>
              <a-select-option value="4 YEAR">4 years</a-select-option>
              <a-select-option value="5 YEAR">5 years</a-select-option>
              <a-select-option value="6 YEAR">6 years</a-select-option>
              <a-select-option value="7 YEAR">7 years</a-select-option>
              <a-select-option value="8 YEAR">8 years</a-select-option>
              <a-select-option value="9 YEAR">9 years</a-select-option>
              <a-select-option value="10 YEAR">10 years</a-select-option>
              <a-select-option value="11 YEAR">11 years</a-select-option>
              <a-select-option value="12 YEAR">12 years</a-select-option>
              <a-select-option value="13 YEAR">13 years</a-select-option>
              <a-select-option value="14 YEAR">14 years</a-select-option>
              <a-select-option value="15 YEAR">15 years</a-select-option>
              <a-select-option value="16 YEAR">16 years</a-select-option>
              <a-select-option value="17 YEAR">17 years</a-select-option>
              <a-select-option value="18 YEAR">18 years</a-select-option>
              <a-select-option value="19 YEAR">19 years</a-select-option>
              <a-select-option value="20 YEAR">20 years</a-select-option>
              <a-select-option value="21 YEAR">21 years</a-select-option>
              <a-select-option value="22 YEAR">22 years</a-select-option>
              <a-select-option value="23 YEAR">23 years</a-select-option>
              <a-select-option value="24 YEAR">24 years</a-select-option>
              <a-select-option value="25 YEAR">25 years</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="form-group">
          <label>Gender</label>
          <div>
            <a-select v-model:value="form.sex" mode="multiple" style="width: 100%" :virtual="false">
              <a-select-option :value="item.id" :key="item.id" v-for="item in sex_items">{{item.name}}</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="form-group">
          <label>Height</label>
          <div>
            <a-input v-model:value="form.height" style="width: 100px;" /> hh (Example, 16.2)
          </div>
        </div>
        <div class="form-group">
          <label>Color</label>
          <div>
            <a-select v-model:value="form.color" mode="multiple" style="width: 100%" :virtual="false">
              <a-select-option :value="item.id" :key="item.id" v-for="item in colors">{{item.name}}</a-select-option>
            </a-select>
          </div>
        </div>
        <div class="form-group">
          <label>Dam</label>
          <div>
            <a-input v-model:value="form.dam" />
          </div>
        </div>
        <div class="form-group">
          <label>Sire</label>
          <div>
            <a-input v-model:value="form.sire" />
          </div>
        </div>
        <div class="form-group">
          <label>Sale/Lease <span class="remarks-text">(Note: You may choose For Sale, For Lease or Both)</span></label>
          <div>
            <a-checkbox :defaultChecked="form.for_sale_status !== SaleStatus.NotAvailable && form.for_sale_status !== SaleStatus.ConsignedToAuction" :checked="form.for_sale_status !== SaleStatus.NotAvailable && form.for_sale_status !== SaleStatus.ConsignedToAuction" @change="forSaleStatusChanged" style="width: 100%; margin: 0;">For Sale</a-checkbox>
            <a-radio-group v-show="form.for_sale_status !== SaleStatus.NotAvailable && form.for_sale_status !== SaleStatus.ConsignedToAuction" v-model:value="form.for_sale_status">
              <div class="d-flex justify-content-start align-items-center" style="margin-top: 10px;">
                <a-radio :value="SaleStatus.WithPrice">Enter Price</a-radio>
                <a-input v-model:value="form.price" style="width: 150px; margin-left: 30px;" :disabled="form.for_sale_status != SaleStatus.WithPrice" />
              </div>
              <div style="margin-top: 10px;">
                <a-radio :value="SaleStatus.WithContact">Contact Seller for Price</a-radio>
              </div>
              <div class="remarks-text" style="margin-top: 8px;" v-if="form.for_sale_status === SaleStatus.WithContact">
                <i class="fa-solid fa-circle-info"></i>
                Including the sales price makes your listing more likely to be found through a filter search.
              </div>
            </a-radio-group>
          </div>
          <div style="margin-top: 8px;">
            <a-checkbox :defaultChecked="form.for_lease_status !== SaleStatus.NotAvailable" @change="forLeaseStatusChanged" style="width: 100%; margin: 0;">For Lease</a-checkbox>
            <a-radio-group v-show="form.for_lease_status !== SaleStatus.NotAvailable" v-model:value="form.for_lease_status">
              <div class="d-flex justify-content-start align-items-center" style="margin-top: 10px;">
                <a-radio :value="SaleStatus.WithPrice">Enter Price Per Year</a-radio>
                <a-input v-model:value="form.lease_price" style="width: 150px; margin-left: 30px;" :disabled="form.for_lease_status != SaleStatus.WithPrice" />
              </div>
              <div style="margin-top: 10px;">
                <a-radio :value="SaleStatus.WithContact">Contact Seller for Price</a-radio>
              </div>
              <div class="remarks-text" style="margin-top: 8px;" v-if="form.for_lease_status === SaleStatus.WithContact">
                <i class="fa-solid fa-circle-info"></i>
                Including the lease price makes your listing more likely to be found through a filter search.
              </div>
            </a-radio-group>
          </div>
          <div style="margin-top: 8px;">
            <a-checkbox :defaultChecked="form.for_sale_status === SaleStatus.ConsignedToAuction" :checked="form.for_sale_status === SaleStatus.ConsignedToAuction" @change="forSaleStatusAuctionChanged" style="width: 100%; margin: 0;">Consigned to Auction</a-checkbox>
            <div class="d-flex justify-content-start align-items-center" style="margin-top: 10px;" v-if="form.for_sale_status === SaleStatus.ConsignedToAuction">
              <div>Starting Bid</div>
              <a-input v-model:value="form.price" style="width: 150px; margin-left: 30px;" :disabled="form.for_sale_status != SaleStatus.ConsignedToAuction" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label>Description</label>
          <label><span class="remarks-text">For best results: Start the description with name, barn name, age, height, breed, gender and location - this ensures that the potential buyer sees all of this information at the beginning of the ad. Then add information about discipline and ideal home/owner. The description should be in 3rd person - don't use words like my, I, our, us, etc. You want your ad to be easily shared and descriptions in 1st person are difficult to share.   Make sure you check for spelling errors, don't use all caps, use correct grammar and complete sentences. Please do not put video links here - they will not link through the description. Put your videos in the video section or in the website section.</span></label>
          <div>
            <a-textarea v-model:value="form.description" :rows="5" showCount :maxlength="1000" />
          </div>
        </div>
        <div class="form-group">
          <label>Website</label>
          <label><span class="remarks-text">This area can be used for seller's personal website, an additional YouTube video or a Facebook link. This area may not be used for other sales horse classified websites - any of these websites will be deleted.</span></label>
          <a-input v-model:value="form.website" />
        </div>
        <div class="form-group">
          <label>Video URL</label>
          <label><span class="remarks-text">We see a 50% higher number of inquiries in horses who have videos on their ad. It's the first question someone asks for - save time by adding it to your ad when you list your horse.</span></label>
          <label><span class="remarks-text">(Note: Enter YouTube video link in the video field - this space will not accept YouTube Shorts or Playlists. This video will also appear on the Mane Street Market app which only accepts YouTube videos.)</span></label>
          <a-input v-model:value="form.video_url" />
        </div>
        <div style="margin-top: 20px;">
          <label>Upload Photos</label>
          <label><span class="remarks-text">Maximum 5 photos. The photo on top will be the primary photo on listing. To reorder the photos, drag and drop in the order you prefer - primary photo on top.</span></label>
          <label><span class="remarks-text">(Note: Clear horizontal photos are best. Vertical photos, video screenshots and cell phone screenshots do not present well. We recommend at least one conformation photo.)</span></label>
          <div>
            <input type="file" ref="images" accept="image/png, image/jpeg, image/heic, image/heic-sequence" data-max-files="5" />
          </div>
        </div>
        <div style="margin-top: 20px;">
          <h4 style="text-align: center; margin-bottom: 20px;">Your contact information</h4>
          <div class="form-group">
            <div>
              <a-radio-group v-model:value="form.contact_information">
                <div><a-radio value="default">Use my account contact information</a-radio></div>
                <div><a-radio value="saved" :disabled="savedContacts == null || !savedContacts.length">Use my saved contact information</a-radio></div>
                <div><a-radio value="other">Use other contact information</a-radio></div>
              </a-radio-group>
            </div>
          </div>
        </div>
        <div v-if="form.contact_information === 'saved'">
          <div class="form-group">
            <a-select v-model:value="form.contact.id" style="width: 100%" :virtual="false">
              <a-select-option :value="contact.id" :key="contact.id" v-for="contact in savedContacts">
                <div>
                  <div>{{ contact.name }}</div>
                  <div>{{ contact.email }}</div>
                  <div>{{ contact.phone }}</div>
                </div>
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div v-else>
          <div class="form-group">
            <label>Name</label>
            <a-input v-model:value="form.contact.name" />
          </div>
          <div class="form-group">
            <label>Phone</label>
            <a-input v-model:value="form.contact.phone" />
          </div>
          <div class="form-group">
            <label>Email</label>
            <a-input v-model:value="form.contact.email" />
          </div>
          <div class="form-group" v-if="form.contact_information === 'other'">
            <a-checkbox v-model:checked="form.contact.save" style="width: 100%; margin: 0;">Save contact information</a-checkbox>
          </div>
        </div>
        <div class="form-group">
          <label>How do you want potential buyers to contact you?</label>
          <div>
            <a-checkbox v-model:checked="form.allowCall" style="width: 100%; margin: 0;">Call</a-checkbox>
            <a-checkbox v-model:checked="form.allowText" style="width: 100%; margin: 0;">Text</a-checkbox>
            <a-checkbox v-model:checked="form.allowEmail" style="width: 100%; margin: 0;">Email</a-checkbox>
          </div>
        </div>
      </div>
      <div class="buttons-container">
        <div class="container d-flex justify-content-end">
          <button class="btn btn-outline-primary" @click="saveDraft()" :disabled="imagesAdded !== imagesUploaded" style="margin-right: 8px;">Save Draft</button>
          <button class="btn btn-primary" @click="previewListing()" :disabled="imagesAdded !== imagesUploaded">Preview Listing</button>
        </div>
      </div>
    </div>
    <div v-if="step === 2 && loading === false">
      <Detail :item="form" :type="'horse'" :preview="true" />
      <hr />
      <div class="container">
        <div class="d-flex justify-content-between">
          <button class="btn btn-info" @click="step = 1">Edit Listing</button>
          <router-link class="btn btn-primary" :to="{path: '/plans', query: {next_page: 'save-listing', next_id: form.id}}" v-if="form.subscription_id == null && form.trainer_package_subscription_id == null">Choose Your Ad Plan</router-link>
          <button class="btn btn-primary" @click="publishListing()" v-if="form.subscription_id != null || form.trainer_package_subscription_id != null">Publish Listing</button>
        </div>
      </div>
    </div>
    <div v-if="step === 3 && loading === false">
      <div class="container">
        <h4 style="text-align: center; margin: 20px 0;">Success!</h4>
        <p style="text-align: center">Thank you for listing your horse for sale with Mane Street Market.</p>
        <p style="text-align: center">Your listing is now live on the website and mobile app.</p>
      </div>
      <hr />
      <div class="container">
        <div class="d-flex justify-content-between">
          <div>
            <button class="btn btn-info" @click="step = 1">Edit Listing</button>
            <router-link class="btn btn-info" style="margin-left: 8px;" :to="{path: '/search', query: {id: form.id}}">View Listing</router-link>
            <button class="btn btn-outline-info" style="margin-left: 8px;" @click="resetForm()">List Another Horse</button>
          </div>
          <router-link class="btn btn-primary" to="/my-listings">Close</router-link>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import {Loader} from '@googlemaps/js-api-loader';
import axios from 'axios';
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';
import Detail from '@/components/Detail.vue';
import * as FilePond from 'filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { SaleStatus } from '@/enums/sale_status';
import heic2any from 'heic2any';
import { fromBlob } from 'image-resize-compress';
import { storage as firebaseStorage } from '@/firebase';

import ASteps from 'ant-design-vue/lib/steps';
import AInput from 'ant-design-vue/lib/input';
import AInputNumber from 'ant-design-vue/lib/input-number';
import ARadio from 'ant-design-vue/lib/radio';
import ACheckbox from 'ant-design-vue/lib/checkbox';
import ASelect from 'ant-design-vue/lib/select';
import ASpin from 'ant-design-vue/lib/spin';
import 'ant-design-vue/lib/steps/style/index.css';
import 'ant-design-vue/lib/input/style/index.css';
import 'ant-design-vue/lib/input-number/style/index.css';
import 'ant-design-vue/lib/radio/style/index.css';
import 'ant-design-vue/lib/checkbox/style/index.css';
import 'ant-design-vue/lib/select/style/index.css';
import 'ant-design-vue/lib/spin/style/index.css';
import Swal from "sweetalert2";
import ConstantsService from '@/services/constants_service';
import Constants from '@/constants/constants';

export default {
  components: {
    BaseLayout,
    SubHeader,
    AInput,
    ASelect,
    ASpin,
    Detail,
    'a-steps': ASteps,
    'a-step': ASteps.Step,
    'a-textarea': AInput.TextArea,
    'a-input-number': AInputNumber,
    'a-radio': ARadio,
    'a-checkbox': ACheckbox,
    'a-radio-button': ARadio.Button,
    'a-radio-group': ARadio.Group,
    'a-select-option': ASelect.Option
  },
  data() {
    return {
      loading: false,
      imagesAdded: 0,
      imagesUploaded: 0,
      step: 1,
      formatter: null,
      google_maps_loader: null,
      mapAutoCompleteListener: null,
      mapAutoComplete: null,
      disciplines: [],
      breeds: [],
      sex_items: [],
      colors: [],
      pond: null,
      new_images: [],
      form: {
        id: null,
        subscription_id: null,
        trainer_package_subscription_id: null,
        horse_name: null,
        horse_or_pony: 'HORSE',
        address: {
          address: null,
          state: null,
          city: null,
          country: null,
          postal_code: null,
          lat: null,
          lng: null,
          hide: false
        },
        discipline: [],
        breed: [],
        sex: [],
        age: null,
        age_unit: null,
        height: 0,
        color: [],
        dam: null,
        sire: null,
        for_sale_status: SaleStatus.NotAvailable,
        price: '$0',
        for_lease_status: SaleStatus.NotAvailable,
        lease_price: '$0',
        description: null,
        contact_information: 'default',
        contact: {
          id: null,
          name: this.$store.state.user.displayName,
          phone: this.$store.state.user.phoneNumber,
          email: this.$store.state.user.email,
          type: 'email',
          save: false
        },
        allowCall: false,
        allowText: false,
        allowEmail: false,
        images: [],
        video_url: null,
        website: null,
      },
      SaleStatus,
      savedContacts: [],
      savedCustomContactLoaded: true
    }
  },
  async mounted() {
    this.formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0
    });
    document.title = 'Create Listing - Mane Street Market';
    window.scrollTo(0, 0);
    ConstantsService.getAllBreeds().then(breeds => this.breeds = breeds);
    ConstantsService.getAllColors().then(colors => this.colors = colors);
    ConstantsService.getAllDisciplines().then(disciplines => this.disciplines = disciplines);
    ConstantsService.getAllSexes().then(sexes => this.sex_items = sexes);
    this.loadSavedContactInfo();
    if (this.$route.query.id) {
      await this.loadListing(this.$route.query.id)
    }
    this.google_maps_loader = new Loader({
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      libraries: ['places'],
      version: Constants.GOOGLE_MAPS_VERSION
    });
    await this.google_maps_loader.load();
    if (this.$route.query.payment_completed && (this.form.subscription_id != null || this.form.trainer_package_subscription_id != null || this.$store.state.is_trainer_package_subscriber)) {
      this.step = 3;
      this.publishListing();
    }
    if (this.step === 1) {
      this.setupForm();
    }
  },
  unmounted() {
    if (this.mapAutoCompleteListener) {
      window.google.maps.event.removeListener(this.mapAutoCompleteListener);
    }
    if (this.mapAutoComplete) {
      window.google.maps.event.clearInstanceListeners(this.mapAutoComplete);
    }
    this.google_maps_loader.deleteScript();
  },
  watch: {
    step(newValue) {
      window.scrollTo(0, 0);
      if (newValue === 1) {
        this.setupForm();
      }
    },
    'form.contact_information'() {
      if (this.form.contact_information === 'default') {
        this.form.contact.name = this.$store.state.user.displayName;
        this.form.contact.email = this.$store.state.user.email;
        this.form.contact.phone = this.$store.state.user.phoneNumber;
      } else if (this.form.contact_information === 'other') {
        if (!this.savedCustomContactLoaded) {
          this.savedCustomContactLoaded = true;
        } else {
          this.form.contact.name = null;
          this.form.contact.email = null;
          this.form.contact.phone = null;
        }
      }
    },
    'form.contact.id'() {
      const selectedContact = this.savedContacts.find(contact => contact.id === this.form.contact.id);
      if (selectedContact) {
        this.form.contact.name = selectedContact.name;
        this.form.contact.email = selectedContact.email;
        this.form.contact.phone = selectedContact.phone;
      }
    },
    'form.price'(newValue) {
      if (newValue) {
        let price = newValue.toString().replace(/[^\d]/g, '');
        this.form.price = this.formatter.format(price);
      }
    },
    'form.lease_price'(newValue) {
      if (newValue) {
        let price = newValue.toString().replace(/[^\d]/g, '');
        this.form.lease_price = this.formatter.format(price);
      }
    },
    'form.height'() {
      if (this.form.height) {
        let height = this.form.height.toString().replace(/[^\d\.]/g, '');
        this.form.height = height;
      }
    },
    'form.discipline'(newValue) {
      this.form.discipline = newValue;
    },
    'form.breed'(newValue) {
      this.form.breed = newValue;
    },
    'form.color'(newValue) {
      this.form.color = newValue;
    },
    'form.sex'(newValue) {
      this.form.sex = newValue;
    },
    'form.horse_name'(newValue) {
      if (newValue) {
        this.form.horse_name = newValue.replaceAll(/[^a-zA-Z ]/g, '').substring(0, 30);
      }
    },
    'form.dam'(newValue) {
      if (newValue != null && newValue.trim().length > 0) {
        this.form.dam = newValue;
      } else {
        this.form.dam = null;
      }
    },
    'form.sire'(newValue) {
      if (newValue != null && newValue.trim().length > 0) {
        this.form.sire = newValue;
      } else {
        this.form.sire = null;
      }
    }
  },
  methods: {
    setupForm() {
      setTimeout(async () => {
        const imageUrls = [];
        if (this.new_images != null && this.new_images.length > 0) {
          for (const image of this.new_images) {
            if (image.file.type != null) { // newly-added image
              imageUrls.push(image.file);
            } else {
              imageUrls.push({
                source: image.data,
                options: {
                  file: {
                    ...image.file
                  }
                }
              });
            }
          }
          this.new_images = [];
        } else if (this.form.images != null && this.form.images.length > 0) {
          for (const imageUrl of this.form.images) {
            if (imageUrl.includes('443')) {
              imageUrls.push(imageUrl);
            } else {
              const ref = firebaseStorage.refFromURL(imageUrl);
              const downloadUrl = await ref.getDownloadURL();
              imageUrls.push(downloadUrl);
            }
          }
        }
        FilePond.registerPlugin(
          FilePondPluginFileValidateType,
          FilePondPluginImagePreview,
          FilePondPluginFileEncode,
          FilePondPluginImageResize,
          FilePondPluginImageTransform,
          FilePondPluginFileValidateSize
        );
        this.pond = FilePond.create(this.$refs.images, {
          allowFileEncode: true,
          allowMultiple: true,
          acceptedFileTypes: ['image/png', 'image/jpeg', 'image/heic', 'image/heic-sequence'],
          fileValidateTypeDetectType: (source, type) => new Promise((resolve, reject) => {
            let name = source.name.toLowerCase();
            if (name.includes('.heic')) {
              resolve('image/heic');
            } else if (name.includes('.heics')) {
              resolve('image/heic-sequence');
            } else {
              resolve(type);
            }
          }),
          imageResizeTargetWidth: 1000,
          imageResizeTargetHeight: 1000,
          imageResizeUpscale: false,
          imageTransformOutputQuality: 60,
          imageTransformOutputMimeType: 'image/jpeg',
          maxFileSize: '10MB',
          labelMaxFileSizeExceeded: 'Photo too large. Please compress to a max of 10MB.',
          allowReorder: true,
          files: imageUrls
        });

        this.pond.oninitfile = (f) => {
          this.imagesAdded++;
        };
        this.pond.onaddfile = (err, f) => {
          this.imagesUploaded++;
        };

        this.mapAutoComplete = new window.google.maps.places.Autocomplete(document.getElementById('autocomplete_search'));
        // this.mapAutoComplete.setComponentRestrictions({
        //   country: ["us", "ca", "ie", "uk", "de", "nl", "be", "fr", "hu", "es", "pt"], // API only allows 5 countries max, so disabling restrictions for now
        // });
        this.mapAutoCompleteListener = window.google.maps.event.addListener(this.mapAutoComplete, 'place_changed', this.searchOnMap);

        if (this.form.address.address) {
          document.getElementById('autocomplete_search').value = this.form.address.address;
        }
      }, 2000);
    },
    async loadListing(id) {
      this.loading = true;
      try {
        let response = await axios.get('/api/horses/' + id);
        if (response.data.contact_information === 'other') {
          this.savedCustomContactLoaded = false;
        }
        this.form = {
          id: id,
          horse_name: response.data.horse_name || null,
          subscription_id: response.data.subscription_id || null,
          trainer_package_subscription_id: response.data.trainer_package_subscription_id || null,
          horse_or_pony: response.data.horse_or_pony || 'HORSE',
          discipline: response.data.discipline || ['Any'],
          breed: response.data.breed || ['Any'],
          sex: response.data.sex || ['Any'],
          age: response.data.age ? `${ response.data.age } ${ response.data.age_unit ?? 'YEAR' }` : '1 YEAR',
          height: response.data.height,
          color: response.data.color,
          dam: response.data.dam,
          sire: response.data.sire,
          for_sale_status: response.data.for_sale_status ?? this.form.for_sale_status,
          price: response.data.price,
          for_lease_status: response.data.for_lease_status ?? this.form.for_lease_status,
          lease_price: response.data.lease_price,
          description: response.data.description,
          contact_information: response.data.contact_information,
          contact: response.data.contact || {
            id: null,
            name: null,
            phone: null,
            email: null,
            save: false,
            type: 'email'
          },
          allowCall: response.data.allowCall === undefined || response.data.allowCall,
          allowText: response.data.allowText === undefined || response.data.allowText,
          allowEmail: response.data.allowEmail === undefined || response.data.allowEmail,
          images: response.data.images || [],
          address: response.data.address || {
            address: null,
            lat: null,
            lng: null,
            postal_code: null,
            city: null,
            state: null,
            country: null,
            hide: false
          },
          video_url: response.data.video_url || null,
          website: response.data.website || null
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    searchOnMap() {
      let place = this.mapAutoComplete.getPlace();
      this.form.address.address = place.formatted_address;
      this.form.address.postal_code = null;
      this.form.address.city = null;
      this.form.address.state = null;
      this.form.address.country = null;
      this.form.address.lat = place.geometry['location'].lat();
      this.form.address.lng = place.geometry['location'].lng();
      for (const component of place.address_components) {
        const addressTypes = component.types;
        if (addressTypes.includes('postal_code')) {
          this.form.address.postal_code = component.short_name;
        } else if (addressTypes.includes('country')) {
          this.form.address.country = component.long_name;
        } else if (addressTypes.includes('administrative_area_level_1')) {
          this.form.address.state = component.short_name;
        } else if (addressTypes.includes('locality')) {
          this.form.address.city = component.long_name;
        } else if (addressTypes.includes('administrative_area_level_3') && this.form.address.city == null) { // do not overwrite locality
          this.form.address.city = component.long_name;
        }
      }
    },
    async saveDraft() {
      this.loading = true;

      try {
        let response = await axios.post('/api/horses/listing-name', {
          id: this.form.id,
          listing_name: this.form.horse_name,
        });
        this.form.id = response.data.id;
      } catch (error) {
        console.error(error);
        Swal.fire({
          title: 'There was an error with your listing',
          text: error.response?.data?.error?.message ?? 'Please fill required fields!'
        });
        this.loading = false;
        this.setupForm();
        return;
      }

      try {
        this.new_images = [];
        for (let image of this.pond.getFiles()) {
          if (image.fileExtension === 'heic' || image.fileExtension === 'heics') {
            const blob = this.b64toBlob(image.getFileEncodeBase64String())
            const jpgBlobs = await heic2any({
              blob,
              toType: 'image/jpeg',
              quality: 1,
              multiple: true
            });
            for (let jpgBlob of jpgBlobs) {
              const smallerJpg = await fromBlob(jpgBlob, 60, 1000, 'auto', 'jpeg');
              const b64 = await this.blobToBase64(smallerJpg);
              this.new_images.push({
                file: image.file,
                size: image.fileSize,
                extension: 'jpeg',
                data: b64.replace('data:image/jpeg;base64,', '')
              });
            }
          } else {
            this.new_images.push({
              file: image.file,
              size: image.fileSize,
              extension: image.fileExtension,
              data: image.getFileEncodeBase64String()
            });
          }
        }
        if (typeof this.form.age === 'string') {
          const ageStr = this.form.age;
          this.form.age = parseInt(ageStr.split(' ')[0]);
          this.form.age_unit = ageStr.split(' ')[1];
        }
        let response = await axios.post('/api/horses/save-draft', {
          id: this.form.id,
          listing_name: this.form.horse_name,
          horse_name: this.form.horse_name,
          horse_or_pony: this.form.horse_or_pony,
          discipline: this.form.discipline,
          breed: this.form.breed,
          age: this.form.age,
          age_unit: this.form.age_unit,
          sex: this.form.sex,
          height: this.form.height,
          color: this.form.color,
          dam: this.form.dam,
          sire: this.dam.sire,
          for_sale_status: this.form.for_sale_status,
          price: this.form.price,
          for_lease_status: this.form.for_lease_status,
          lease_price: this.form.lease_price,
          description: this.form.description,
          contact_information: this.form.contact_information,
          contact: this.form.contact,
          allowCall: this.form.allowCall,
          allowText: this.form.allowText,
          allowEmail: this.form.allowEmail,
          images: this.form.images,
          new_images: this.new_images,
          address: this.form.address,
          video_url: this.form.video_url,
          website: this.form.website
        });
        this.form.images = response.data.images;
        Swal.fire({
          title: 'Draft saved',
          text: 'Your listing is now saved as draft'
        });
        this.$router.push({ path: '/my-listings', replace: true });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: 'There was an error with your listing',
          text: error.response?.data?.error?.message ?? 'Please fill required fields!'
        });
        this.setupForm();
      } finally {
        this.loading = false;
      }
    },
    async previewListing() {
      if (!this.form.allowCall && !this.form.allowText && !this.form.allowEmail) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: 'Please select at least one form of contact',
        });
        return;
      }

      if (this.form.for_sale_status == SaleStatus.NotAvailable && this.form.for_lease_status == SaleStatus.NotAvailable) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: 'Please select at least one of For Sale, For Lease or Consigned to Auction'
        });
        return;
      }

      if (this.form.address.address == null) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: 'Please select an address in the drop down list. If you do not want address to show on listing, click "Do Not Show Address".'
        });
        return;
      }

      if (this.form.video_url != null && this.form.video_url.trim() != '' && !/\/watch\?v\=|youtu\.be\/\w+/gi.test(this.form.video_url)) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: 'Incorrect Video URL. Valid example: https://www.youtube.com/watch?v=VIDEO_ID'
        });
        return;
      }

      if (this.form.discipline == null || this.form.discipline.length === 0) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: 'Please select at least one discipline'
        });
        return;
      }

      if (this.form.breed == null || this.form.breed.length === 0) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: 'Please select a breed'
        });
        return;
      }

      if (this.form.sex == null || this.form.sex.length === 0) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: 'Please select a gender'
        });
        return;
      }

      if (this.form.color == null || this.form.color.length === 0) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: 'Please select a color'
        });
        return;
      }

      if (this.form.contact_information === 'saved' && this.form.contact.id == null) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: 'Please select a saved contact'
        });
        return;
      }

      if (this.form.age == null) {
        Swal.fire({
          title: 'There was an error with your listing',
          text: 'Please select age'
        });
        return;
      }

      this.loading = true;

      try {
        let response = await axios.post('/api/horses/listing-name', {
          id: this.form.id,
          listing_name: this.form.horse_name,
        });
        this.form.id = response.data.id;
      } catch (error) {
        console.error(error);
        Swal.fire({
          title: 'There was an error with your listing',
          text: error.response?.data?.error?.message ?? 'Please fill required fields!'
        });
        this.loading = false;
        this.setupForm();
        return;
      }

      try {
        this.new_images = [];
        axios.post('/api/log-client-error', {
          type: 'info',
          location: window.location.href,
          message: 'this.pond.getFiles()',
          data: JSON.stringify(this.pond.getFiles())
        });
        for (let image of this.pond.getFiles()) {
          if (image.fileExtension === 'heic' || image.fileExtension === 'heics') {
            const blob = this.b64toBlob(image.getFileEncodeBase64String())
            const jpgBlobs = await heic2any({
              blob,
              toType: 'image/jpeg',
              quality: 1,
              multiple: true
            });
            for (let jpgBlob of jpgBlobs) {
              const smallerJpg = await fromBlob(jpgBlob, 60, 1000, 'auto', 'jpeg');
              const b64 = await this.blobToBase64(smallerJpg);
              this.new_images.push({
                file: image.file,
                size: image.fileSize,
                extension: 'jpeg',
                data: b64.replace('data:image/jpeg;base64,', '')
              });
            }
          } else {
            this.new_images.push({
              file: image.file,
              size: image.fileSize,
              extension: image.fileExtension,
              data: image.getFileEncodeBase64String()
            });
          }
        }
        axios.post('/api/log-client-error', {
          type: 'info',
          location: window.location.href,
          message: 'this.new_images',
          data: JSON.stringify(this.new_images)
        });
        if (typeof this.form.age === 'string') {
          const ageStr = this.form.age;
          this.form.age = parseInt(ageStr.split(' ')[0]);
          this.form.age_unit = ageStr.split(' ')[1];
        }
        let response = await axios.post('/api/horses/details', {
          id: this.form.id,
          listing_name: this.form.horse_name,
          horse_name: this.form.horse_name,
          horse_or_pony: this.form.horse_or_pony,
          discipline: this.form.discipline,
          breed: this.form.breed,
          age: this.form.age,
          age_unit: this.form.age_unit,
          sex: this.form.sex,
          height: this.form.height,
          color: this.form.color,
          dam: this.form.dam,
          sire: this.form.sire,
          for_sale_status: this.form.for_sale_status,
          price: this.form.price,
          for_lease_status: this.form.for_lease_status,
          lease_price: this.form.lease_price,
          description: this.form.description,
          contact_information: this.form.contact_information,
          contact: this.form.contact,
          allowCall: this.form.allowCall,
          allowText: this.form.allowText,
          allowEmail: this.form.allowEmail,
          images: this.form.images,
          new_images: this.new_images,
          address: this.form.address,
          video_url: this.form.video_url,
          website: this.form.website
        });
        this.form.images = response.data.images;
        this.step = 2;
      } catch (error) {
        console.log(error);
        axios.post('/api/log-client-error', {
          type: 'error',
          location: window.location.href,
          message: error.toString(),
          data: JSON.stringify(error)
        });
        Swal.fire({
          title: 'There was an error with your listing',
          text: error.response?.data?.error?.message ?? 'Please fill required fields!'
        });
        this.setupForm();
      } finally {
        this.loading = false;
      }
    },
    async publishListing() {
      this.loading = true;
      try {
        await axios.post('/api/horses/publish', {id: this.form.id});
        this.step = 3;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    removeImage(index) {
      this.form.images.splice(index, 1);
    },
    forSaleStatusChanged(e) {
      this.form.for_sale_status = e.target.checked ? SaleStatus.WithPrice : SaleStatus.NotAvailable;
    },
    forLeaseStatusChanged(e) {
      this.form.for_lease_status = e.target.checked ? SaleStatus.WithPrice : SaleStatus.NotAvailable;
    },
    forSaleStatusAuctionChanged(e) {
      this.form.for_sale_status = e.target.checked ? SaleStatus.ConsignedToAuction : SaleStatus.NotAvailable;
    },
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    },
    blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
    resetForm() {
      this.form = {
        id: null,
        subscription_id: null,
        trainer_package_subscription_id: null,
        horse_name: null,
        horse_or_pony: 'HORSE',
        address: {
          address: null,
          state: null,
          city: null,
          country: null,
          postal_code: null,
          lat: null,
          lng: null,
          hide: false
        },
        discipline: [],
        breed: [],
        sex: [],
        age: null,
        age_unit: null,
        height: 0,
        color: [],
        dam: null,
        sire: null,
        for_sale_status: SaleStatus.NotAvailable,
        price: '$0',
        for_lease_status: SaleStatus.NotAvailable,
        lease_price: '$0',
        description: null,
        contact_information: 'default',
        contact: {
          id: null,
          name: this.$store.state.user.displayName,
          phone: this.$store.state.user.phoneNumber,
          email: this.$store.state.user.email,
          type: 'email',
          save: false
        },
        allowCall: false,
        allowText: false,
        allowEmail: false,
        images: [],
        video_url: null,
        website: null,
      };
      this.$router.replace({ query: null });
      this.step = 1;
    },
    async loadSavedContactInfo() {
      const response = await axios.get('/api/my-saved-contacts');
      this.savedContacts = response.data;
    }
  }
}
</script>

<style lang="scss" scoped>
  .remarks-text {
    font-size: 0.9rem;
    color: gray;
  }

  ::v-deep(.ant-steps) {
    padding-top: 4px;
    overflow: hidden;
    justify-content: center;

    .ant-steps-item {
      max-width: 33%;
    }

    .ant-steps-item-process .ant-steps-item-icon {
      width: 8px;
      height: 8px;
    }
  }

  .pricing-button {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-style: italic;
  }

  .buttons-container {
    position: sticky;
    bottom: 0;
    padding: 1rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    max-width: none;
    margin-bottom: -1rem;
    background: white;
  }

  @media screen and (max-width: 480px) {
    .pricing-button {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
  }
</style>
