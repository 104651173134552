<template>
  <div class="d-flex justify-content-center align-items-center loading" v-if="map_loading"><a-spin /></div>
  <div id="map" :style="[map_loading ? { 'display': 'none' } : { 'display': 'block' }]"></div>
</template>

<script>
import {Loader} from '@googlemaps/js-api-loader';
import ASpin from 'ant-design-vue/lib/spin';
import 'ant-design-vue/lib/spin/style/index.css';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import Constants from '@/constants/constants';

export default {
  components: {
    ASpin
  },
  props: {
    loading: Boolean,
    items: Array
  },
  data() {
    return {
      google_maps_loader: null,
      map: null,
      map_loading: true,
      markers: [],
      marker_clusterer: null,
      states: {
        southeast: {
          al: {state: 'al', name: 'Alabama', lat: 32.318230, lng: -86.902298},
          fl: {state: 'fl', name: 'Florida', lat: 27.994402, lng: -81.760254},
          ga: {state: 'ga', name: 'Georgia', lat: 33.247875, lng: -83.441162},
          nc: {state: 'nc', name: 'North Carolina', lat: 35.782169, lng: -80.793457},
          sc: {state: 'sc', name: 'South Carolina', lat: 33.836082, lng: -81.163727},
          tn: {state: 'tn', name: 'Tennessee', lat: 35.860119, lng: -86.660156},
        },
        northeast: {
          ct: {state: 'ct', name: 'Connecticut', lat: 41.599998, lng: -72.699997},
          de: {state: 'de', name: 'Delaware', lat: 39.000000, lng: -75.500000},
          in: {state: 'in', name: 'Indiana', lat: 40.273502, lng: 86.126976},
          ky: {state: 'ky', name: 'Kentucky', lat: 37.839333, lng: -84.270020},
          me: {state: 'me', name: 'Maine', lat: 45.367584, lng: -68.972168},
          md: {state: 'md', name: 'Maryland', lat: 39.045753, lng: -76.641273},
          ma: {state: 'ma', name: 'Massachusetts', lat: 42.407211, lng: -71.382439},
          nh: {state: 'nh', name: 'New Hampshire', lat: 44.000000, lng: -71.500000},
          nj: {state: 'nj', name: 'New Jersey', lat: 39.833851, lng: -74.871826},
          ny: {state: 'ny', name: 'New York', lat: 43.000000, lng: -75.000000},
          pa: {state: 'pa', name: 'Pennsylvania', lat: 41.203323, lng: -77.194527},
          ri: {state: 'ri', name: 'Rhode Island', lat: 41.700001, lng: -71.500000},
          vt: {state: 'vt', name: 'Vermont', lat: 44.000000, lng: -72.699997},
          va: {state: 'va', name: 'Virginia', lat: 37.926868, lng: -78.024902},
          wa: {state: 'wa', name: 'Washington D.C.', lat: 47.751076, lng: -120.740135},
          wv: {state: 'wv', name: 'West Virginia', lat: 39.000000, lng: -80.500000},
        },
        mountain: {
          az: {state: 'az', name: 'Arizona', lat: 34.048927, lng: -111.093735},
          co: {state: 'co', name: 'Colorado', lat: 39.113014, lng: -105.358887},
          id: {state: 'id', name: 'Idaho', lat: 44.068203, lng: -114.742043},
          mt: {state: 'mt', name: 'Montana', lat: 46.965260, lng: -109.533691},
          nm: {state: 'nm', name: 'New Mexico', lat: 34.307144, lng: -106.018066},
          ut: {state: 'ut', name: 'Utah', lat: 39.419220, lng: -111.950684},
          wy: {state: 'wy', name: 'Wyoming', lat: 43.075970, lng: -107.290283},
        },
        pacific: {
          ca: {state: 'ca', name: 'California', lat: 36.778259, lng: -119.417931},
          nv: {state: 'nv', name: 'Nevada', lat: 39.876019, lng: -117.224121},
          or: {state: 'or', name: 'Oregon', lat: 44.000000, lng: -120.500000},
          wa: {state: 'wa', name: 'Washington', lat: 47.751076, lng: -120.740135},
        },
        central: {
          ar: {state: 'ar', name: 'Arkansas', lat: 34.799999, lng: -92.199997},
          il: {state: 'il', name: 'Illinois', lat: 40.000000, lng: -89.000000},
          ia: {state: 'ia', name: 'Iowa', lat: 42.032974, lng: -93.581543},
          ks: {state: 'ks', name: 'Kansas', lat: 38.500000, lng: -98.000000},
          la: {state: 'la', name: 'Louisiana', lat: 30.391830, lng: -92.329102},
          mi: {state: 'mi', name: 'Michigan', lat: 44.182205, lng: -84.506836},
          ms: {state: 'ms', name: 'Mississippi', lat: 33.000000, lng: -90.000000},
          mo: {state: 'mo', name: 'Missouri', lat: 38.573936, lng: -92.603760},
          ne: {state: 'ne', name: 'Nebraska', lat: 41.500000, lng: -100.000000},
          nd: {state: 'nd', name: 'North Dakota', lat: 47.650589, lng: -100.437012},
          oh: {state: 'oh', name: 'Ohio', lat: 40.367474, lng: -82.996216},
          ok: {state: 'ok', name: 'Oklahoma', lat: 36.084621, lng: -96.921387},
          sd: {state: 'sd', name: 'South Dakota', lat: 44.500000, lng: -100.000000},
          tx: {state: 'tx', name: 'Texas', lat: 31.000000, lng: -100.000000},
          wi: {state: 'wi', name: 'Wisconsin', lat: 44.500000, lng: -89.500000},
        }
      }
    }
  },
  async mounted() {
    window.markers = [];
    this.google_maps_loader = new Loader({
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      libraries: ['places'],
      version: Constants.GOOGLE_MAPS_VERSION
    });
    await this.google_maps_loader.load();
    let mapOptions = {
      zoom: 4,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false
    };
    this.map = new window.google.maps.Map(document.getElementById('map'), mapOptions);
    if (this.$route.query.lat != null && this.$route.query.lng != null) {
      if (this.$route.query.region === 'northeast') {
        this.map.setCenter(new window.google.maps.LatLng(43.29959518461236, -74.21778198461692));
        this.map.setZoom(6);
      } else if (this.$route.query.region === 'southeast') {
        this.map.setCenter(new window.google.maps.LatLng(33.753746, -84.386330));
        this.map.setZoom(6);
      } else if (this.$route.query.region === 'central') {
        this.map.setCenter(new window.google.maps.LatLng(40.30158703590373, -98.3416636837471));
        this.map.setZoom(6);
      } else {
        this.map.setCenter(new window.google.maps.LatLng(this.$route.query.lat, this.$route.query.lng));
        if (this.$route.query.zoom != null) {
          this.map.setZoom(parseInt(this.$route.query.zoom));
        } else {
          this.map.setZoom(8);
        }
      }
    } else if (this.$route.query.region != null) {
      let bounds = new google.maps.LatLngBounds();
      for (let state of Object.values(this.states[this.$route.query.region])) {
        bounds.extend({lat: state.lat, lng: state.lng});
      }
      this.map.fitBounds(bounds);
      this.map.setZoom(6);
    } else {
      this.map.setCenter(new google.maps.LatLng(39, -95));
      this.map.setZoom(4);
    }
    /*
      UPDATE 2021/09/09 (JOSH)
      To fix pinch zoom bug on mobile
      Stopped using zoom_changed listener and using idle listener instead
      Also made changes to package file @googlemaps/markerclustererplus/dist/index.esm.js:688
      Refer to: https://github.com/googlemaps/js-markerclustererplus/issues/76
    */
    // window.google.maps.event.addListener(this.map, 'zoom_changed', () => {
    //   this.refreshMarkers(false);
    // });
    window.google.maps.event.addListener(this.map, 'idle', () => {
      this.refreshMarkers(false);
      this.marker_clusterer.resetViewport_();
      this.marker_clusterer.redraw_();
    });
    this.refreshMarkers();
  },
  unmounted() {
    window.markers = [];
    window.google.maps.event.clearInstanceListeners(this.map);
    this.google_maps_loader.deleteScript();
  },
  watch: {
    items() {
      this.refreshMarkers(true, true);
    },
    '$route.query.lat'() {
      if (this.$route.query.lat != null && this.$route.query.lng != null) {
        this.map.setCenter(new google.maps.LatLng(this.$route.query.lat, this.$route.query.lng));
        this.map.setZoom(8);
      } else {
        this.map.setCenter(new google.maps.LatLng(39, -95));
        this.map.setZoom(4);
      }
    }
  },
  methods: {
    refreshMarkers(setMarkers = true, setEndLoading = false) {
      let $this = this;
      for (let marker of window.markers) {
        marker.setMap(null);
      }
      if (this.marker_clusterer != null) {
        this.marker_clusterer.clearMarkers();
      }
      if (setMarkers) {
        window.markers = [];
        this.map_loading = true;
        for (let item of this.items) {
          if (item.address && item.address.lat && item.address.lng) {
            let latLng = new window.google.maps.LatLng(item.address.lat, item.address.lng);

            // offset marker a little if overlapping
            // if (window.markers.length != 0) {
            //   for (let i = 0; i < window.markers.length; i++) {
            //     let existingMarker = window.markers[i];
            //     let pos = existingMarker.getPosition();
            //     if (latLng.equals(pos)) {
            //       let a = 360.0 / markers.length;
            //       let newLat = pos.lat() + -.00004 * Math.cos((+a * i) / 180 * Math.PI); // x
            //       let newLng = pos.lng() + -.00004 * Math.sin((+a * i) / 180 * Math.PI); // y
            //       latLng = new google.maps.LatLng(newLat, newLng);
            //     }
            //   }
            // }
            
            let marker = new window.google.maps.Marker({
              map: this.map,
              position: latLng,
              title: item.business_name,
              id: item.id,
              // icon: item.type === 'Real Estate' ? require('@/assets/img/home-map-pin.png') : (item.type === 'Job Listings' ? require('@/assets/img/briefcase-map-pin.png') : null)
            });
            marker.addListener('click', () => {
              $this.$parent.openDetails(item);
            });
            window.markers.push(marker);
          }
        }
      }
      this.marker_clusterer = new MarkerClusterer(this.map, window.markers, {
        imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        zoomOnClick: false,
        gridSize: 20
      });
      window.google.maps.event.addListener(this.marker_clusterer, 'clusterclick', cluster => {
        let ids = cluster.markers_.map(c => c.get('id'));
        $this.$parent.openList(ids);
      });
      if (setMarkers) { // refresh after initial set-up - Maps bug that sometimes show individual markers even when clusters are present
        this.refreshMarkers(false);
      }
      if (setEndLoading || (this.items != null && this.items.length > 0)) {
        this.map_loading = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#map {
  width: 100%; height: 100%;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>