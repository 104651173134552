import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {db, auth} from '@/firebase';
import axios from 'axios';
import { Form } from 'ant-design-vue';

axios.interceptors.request.use(
    async function (reqConfig) {
        const user = auth.currentUser;
        if (user) {
            try {
                const token = await user.getIdToken(false);
                store.dispatch('setToken', token);
                reqConfig.headers.Authorization = 'Bearer ' + token;
            } catch (error) {
                console.error('Error obtaining auth token in interceptor:', error);
            }
        }
        return reqConfig;
    },
    err => Promise.reject(err)
);

auth.onAuthStateChanged(function (user) {
    store.dispatch('setUser', user);
    if (user) {
        user.getIdToken(true).then(token => {
            store.dispatch('setToken', token);
        });
        db.collection('Users').doc(user.uid).onSnapshot((userProfile) => {
            let profileData = userProfile.data();
            if (typeof profileData === 'undefined') {
                // axios.post('/api/auth/social-register')
                //     .finally(() => {
                //         db.collection('Users').doc(user.uid).onSnapshot((userProfile) => {
                //             let profileData = userProfile.data();
                //             store.dispatch('setProfile', profileData);
                //         })
                //     });
            } else {
                store.dispatch('setProfile', profileData);
            }
        });
    } else {
        store.dispatch('setToken', null);
        store.dispatch('setProfile', null);
    }
});

window.addEventListener('error', e => {
    if (!e.toString().includes('404') && !window.location.hostname.includes('localhost') && !window.location.hostname.includes('-dev')) {
        axios.post('/api/log-client-error', {
            type: 'error',
            location: window.location.href,
            message: e.toString(),
            data: JSON.stringify(e)
        });
    }
});

window.addEventListener('unhandledrejection', e => {
    if (!e.reason.toString().includes('404') && !window.location.hostname.includes('localhost') && !window.location.hostname.includes('-dev')) {
        axios.post('/api/log-client-error', {
            type: 'unhandledrejection',
            location: window.location.href,
            message: e.reason.toString(),
            data: JSON.stringify(e.reason)
        });
    }
});

createApp(App).use(store).use(router).use(Form).mount('#app')
