<template>
  <div class="main-content" :class="customClass">
    <slot></slot>
  </div>
  <div id="contact-bubble-container">
    <div id="contact-prompt" @click="openContactModal()">
      <button @click="closeContactPrompt()">
        <i class="fa-solid fa-xmark"></i>
      </button>
      <span>Welcome to Mane Street Market - how can we help you?</span>
    </div>
    <div id="contact-bubble" @click="openContactModal()">
      <i class="fa-solid fa-message"></i>
    </div>
  </div>
  <div id="contact-modal">
    <button class="close-button" @click="closeContactModal()">
      <i class="fa-solid fa-xmark"></i>
    </button>
    <div class="contact-modal-input-container">
      <div class="contact-modal-row">
        <label>Your name</label>
        <input v-model="name" />
      </div>
      <div class="contact-modal-row">
        <label>Your email</label>
        <input v-model="email" />
      </div>
      <div class="contact-modal-row">
        <label>Inquiry message</label>
        <textarea rows="6" v-model="message"></textarea>
      </div>
    </div>
    <div>
      <a-button class="submit-button" :loading="loading" @click="sendContactMessage()" :disabled="!canSubmitContactForm || loading">{{ loading ? '' : 'Send message' }}</a-button>
    </div>
  </div>
  <div class="footer" v-if="showFooter">
    <div class="container">
      <div class="d-flex justify-content-between flex-wrap" style="column-gap: 24px;">
        <div>
          <ul class="list-unstyled">
            <li style="margin-bottom: 8px;"><router-link to="/plans">Listing / Advertising Plans and Pricing</router-link></li>
            <li style="margin-bottom: 8px;"><router-link to="/benefits-of-msm">Benefits of MSM</router-link></li>
            <li style="margin-bottom: 8px;"><router-link to="/my-listings" v-if="$store.state.token != null">Manage Listings</router-link></li>
            <li style="margin-bottom: 8px;"><a href="javascript:;" @click="openLoginModal()" v-if="$store.state.token == null">Manage Listings</a></li>
          </ul>
        </div>
        <div>
          <ul class="list-unstyled">
            <li style="margin-bottom: 8px;"><router-link to="/get-the-msm-app">Get the MSM App</router-link></li>
            <li style="margin-bottom: 8px;"><router-link to="/my-saved-filters">My Saved Searches</router-link></li>
          </ul>
        </div>
        <div>
          <ul class="list-unstyled">
            <li style="margin-bottom: 8px;"><router-link to="/terms-of-use">Terms of Use</router-link></li>
            <li style="margin-bottom: 8px;"><router-link to="/privacy-policy">Privacy Policy</router-link></li>
            <li style="margin-bottom: 8px;"><router-link to="/faq">FAQ's</router-link></li>
            <li style="margin-bottom: 8px;"><router-link to="/contact-us">Contact Us</router-link></li>
          </ul>
        </div>
        <div>
          <ul class="list-unstyled">
            <li style="margin-bottom: 8px;"><router-link to="/about">About</router-link></li>
            <li style="margin-bottom: 8px;"><a target="_blank" href="https://www.facebook.com/manestreetmarket/">Facebook</a></li>
            <li style="margin-bottom: 8px;"><a target="_blank" href="https://www.instagram.com/manestreetmarket/">Instagram</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from 'axios';
import Swal from 'sweetalert2';
import AButton from 'ant-design-vue/lib/button';
import 'ant-design-vue/lib/input/style/index.css';

export default {
  components: {
    AButton
  },
  props: {
    'customClass': Object,
    'showFooter': {
      type: Boolean,
      default: true
    }
  },
  async mounted() {
    if (sessionStorage.getItem('contactPromptClosed') == null) {
      $('#contact-prompt').show();
    }
  },
  data() {
    return {
      loading: false,
      name: '',
      email: '',
      message: ''
    }
  },
  computed: {
    canSubmitContactForm() {
      return this.name.trim().length && this.email.trim().length && this.message.trim().length;
    }
  },
  methods: {
    openLoginModal() {
      $('#loginModal').modal('show');
      $('#myTab a[href="#sign-in-content"]').tab('show')
    },
    closeContactPrompt() {
      $('#contact-prompt').hide();
      sessionStorage.setItem('contactPromptClosed', true);
    },
    openContactModal() {
      $('#contact-modal').css('display', 'flex');
      this.closeContactPrompt();
    },
    closeContactModal() {
      $('#contact-modal').hide();
    },
    sendContactMessage() {
      this.loading = true;
      axios.post('/api/submit-contact', {
        name: this.name,
        email: this.email,
        message: this.message
      }).then(() => {
        Swal.fire({
          title: 'Message Sent',
          text: 'We have received your message and will respond shortly.'
        });
        this.closeContactModal();
        this.clearContactModal();
      }).catch(err => {
        if (err?.response?.data?.error?.message) {
          err = err.response.data.error.message;
        } else {
          err = 'An error has occurred. Please try again.';
        }
        Swal.fire({
          title: 'Error sending message',
          text: err
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    clearContactModal() {
      this.name = '';
      this.email = '';
      this.message = '';
    }
  }
}
</script>

<style lang="scss" scoped>
  .main-content {
    padding-bottom: 32px;
  }
  
  .footer {
    @media only screen and (max-width: 960px) {
      .d-flex div {
        flex-grow: 1;
        width: 40%;
      }
    }
  }

  #contact-bubble-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: fixed;
    bottom: 24px;
    right: 24px;
    align-items: flex-end;
  }

  #contact-bubble {
    background-color: #051548;
    border-radius: 100%;
    width: 64px;
    height: 64px;
    padding: 16px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.2) 0px 2px 12px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
  }

  #contact-prompt {
    display: none;
    background-color: white;
    padding: 12px 16px;
    border-radius: 4px;
    max-width: 200px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.2) 0px 2px 12px;
    cursor: pointer;
    position: relative;
  }

  #contact-prompt button {
    position: absolute;
    top: 8px;
    right: 8px;
    outline: none;
    appearance: none;
    background: none;
    border: none;
  }

  #contact-prompt button svg {
    width: 10px;
    color: darkgray;
  }

  #contact-bubble:hover {
    transform: scale(1.1);
    opacity: 0.9;
  }

  #contact-bubble svg {
    color: white;
    width: 100%;
    height: 100%;
  }

  #contact-modal {
    display: none;
    position: fixed;
    right: 24px;
    bottom: 112px;
    background-color: white;
    border-radius: 4px;
    padding: 16px;
    flex-direction: column;
    gap: 24px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.2) 0px 2px 12px;
    width: 300px;
    max-width: calc(100vw - 48px);
    z-index: 99;
  }

  #contact-modal .contact-modal-input-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  #contact-modal .contact-modal-row {
    display: flex;
    flex-direction: column;
  }

  #contact-modal input {
    padding: 4px 8px;
    border: 1px solid lightgray;
  }

  #contact-modal textarea {
    resize: none;
    padding: 4px 8px;
    border: 1px solid lightgray;
  }

  #contact-modal .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    outline: none;
    appearance: none;
    background: none;
    border: none;
  }

  #contact-modal .close-button svg {
    width: 10px;
    color: darkgray;
  }

  #contact-modal .submit-button {
    width: 100%;
    background-color: #051548;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
  }

  #contact-modal .submit-button:hover {
    opacity: 0.9;
  }

  #contact-modal .submit-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    #contact-modal {
      width: 100%;
    }
  }
</style>