<template>
  <div class="listing-block-container" v-if="type === 'horse' || type === 'service' || type === 'equipment'">
    <div class="cover">
      <div style="position: absolute; right: 0; top: 0;">
        <a href="javascript:;" style="padding: 5px; border-bottom-left-radius: 5px; background: #fff;" @click="toggleFavorite(item);">
          <HeartOutlined v-if="isFavorited(item) === false" />
          <HeartFilled v-if="isFavorited(item)" />
        </a>
      </div>
      <img class="horse-img"
        @click.self="$parent.$parent.openDetails(item, type)"
        :src="item.images.length > 0 ? item.images[0] : placeholderImg"
        :class="item.images.length > 0 ? 'horse-img' : 'placeholder-img'"
      />
      <div class="details" v-if="type === 'horse'" @click="$parent.$parent.openDetails(item, type)">
        <div class="price" v-if="item.for_sale_status === SaleStatus.WithPrice || item.price_must_contact === false">{{ formatPrice(item.price) }}</div>
        <div class="price" v-if="item.for_sale_status === SaleStatus.WithContact || item.price_must_contact || item.for_lease_status === SaleStatus.WithContact">Contact seller for price</div>
        <div class="price" v-if="item.for_lease_status === SaleStatus.WithPrice">Lease: {{ formatPrice(item.lease_price) }}/year</div>
        <div class="price" v-if="item.for_sale_status === SaleStatus.ConsignedToAuction">Auction: Starting {{ formatPrice(item.price) }}</div>
        <div class="truncated-text"><strong>Name: </strong> {{ item.horse_name }}</div>
        <div class="truncated-text"><strong>Breed: </strong>{{ item.breed && item.breed.length > 0 ? item.breed[0] : '' }}</div>
        <div class="truncated-text"><strong>Discipline: </strong>{{ item.discipline && item.discipline.length > 0 ? item.discipline[0] : '' }}</div>
        <div class="truncated-text"><strong>Age: </strong>{{item.age > 0 || item.age_unit !== 'UTERO' ? item.age : ''}}{{item.age_unit === 'UTERO' ? 'Utero' : item.age_unit === 'MONTH' ? ' months old' : ' years old'}}</div>
        <!-- <div class="location" v-if="item.address.hide === false"><strong>Location: </strong>{{item.address.address}}</div> -->
      </div>
      <div class="details" v-if="type === 'service'" @click="$parent.$parent.openDetails(item, type)">
        <div class="truncated-text"><strong>Name: </strong> {{ item.business_name }}</div>
        <div class="truncated-text"><strong>Type: </strong> {{ item.type }}</div>
        <div class="truncated-text"><strong>Website: </strong> {{ item.website || '-' }}</div>
        <div class="truncated-text"><strong>Contact: </strong> {{ item.contact?.name }}</div>
      </div>
      <div class="details" v-if="type === 'equipment'" @click="$parent.$parent.openDetails(item, type)">
        <div class="price" v-if="item.price">{{ formatPrice(item.price) }}</div>
        <div class="truncated-text"><strong>Name: </strong> {{ item.listing_name }}</div>
        <div class="truncated-text"><strong>Type: </strong> {{ item.type }}</div>
        <div class="truncated-text" v-if="item.address.hide === false"><strong>Location: </strong>{{item.address.address}}</div>
        <div class="truncated-text" v-if="item.address.hide === true"><strong>Location: </strong>{{item.address.city}}, {{item.address.state}}</div>
      </div>
    </div>
  </div>
  <div class="listing-block-container" v-if="type === 'ad'">
    <div class="cover" @click="openAdWebsite(item)">
      <img class="ad-img" :src="item.images[0]" v-if="item.images.length > 0" />
    </div>
  </div>
</template>

<script>
import { HeartOutlined, HeartFilled } from '@ant-design/icons-vue';
import $ from "jquery";
import { SaleStatus } from '@/enums/sale_status';

export default {
  components: {
    HeartOutlined,
    HeartFilled
  },
  props: {
    type: String,
    item: Object
  },
  data() {
    return {
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }),
      placeholderImg: require('@/assets/img/placeholder_logo.png'),
      SaleStatus
    }
  },
  methods: {
    formatPrice(value) {
      return this.formatter.format(value);
    },
    toggleFavorite(item) {
      if (this.$store.state.user == null) {
        $('#loginModal').modal('show');
        $('#myTab a[href="#sign-in-content"]').tab('show')
        return;
      }
      if (this.type === 'horse') {
        this.$store.dispatch('toggleFavoriteHorse', item.id);
      } else if (this.type === 'service') {
        this.$store.dispatch('toggleFavoriteService', item.id);
      } else if (this.type === 'equipment') {
        this.$store.dispatch('toggleFavoriteEquipment', item.id);
      }
    },
    isFavorited(item) {
      if (this.type === 'horse') {
        return this.$store.state.favorite_horses.indexOf(item.id) > -1;
      } else if (this.type === 'service') {
        return this.$store.state.favorite_services.indexOf(item.id) > -1;
      } else if (this.type === 'equipment') {
        return this.$store.state.favorite_equipments.indexOf(item.id) > -1;
      }
    },
    openAdWebsite(item) {
      if (item.website) {
        if (!item.website.match(/^[a-zA-Z]+:\/\//)) {
          window.open(`https://${ item.website }`, '_blank');
        } else {
          window.open(item.website, '_blank');
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .listing-block-container {
    width: 49%;
    margin-bottom: 10px;
    font-size: 12px;
    border: 1px solid #ddd;
    cursor: pointer;
    height: 260px;

    .cover {
      width: 100%;
      height: 260px;;
      margin-right: 10px;
      text-align: center;
      padding: 4px;
      position: relative;

      .ad-img {
        object-fit: cover;
        width: 100%;
        height: 250px;
      }

      .horse-img {
        object-fit: cover;
        width: 100%;
        height: 150px;
      }

      .placeholder-img {
        object-fit: contain;
        width: 120px;
        height: 150px;
        border: none;
        padding: 8px;
      }

      .details {
        padding: 4px;
        text-align: left;

        .price {
          margin-bottom: 5px;
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
  }

  .truncated-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>
