<template>
  <div class="d-flex justify-content-center align-items-center loading" v-if="loading"><a-spin /></div>
  <div class="d-flex justify-content-start flex-wrap" v-if="loading === false" >
    <div class="item" v-for="item in items" :key="item.id">
      <div class="cover" style="width: 120px; height: 150px; margin-right: 10px; position: relative">
        <div style="position: absolute; right: 0; top: 0;">
          <a href="javascript:;" style="padding: 5px; border-bottom-left-radius: 5px; background: #fff;" @click="toggleFavorite(item);">
            <HeartOutlined v-if="isFavorited(item) === false" />
            <HeartFilled v-if="isFavorited(item)" />
          </a>
        </div>
        <img @click.self="$parent.openDetails(item)" src="../assets/img/placeholder_logo.png" v-if="item.images == null || item.images?.length === 0" style="object-fit: contain; width: 120px; height: 150px; border: none; padding: 8px;" />
        <img @click.self="$parent.openDetails(item)" :src="item.images[0]" v-if="item.images != null && item.images?.length > 0" style="object-fit: cover; width: 120px; height: 150px;" />
      </div>
      <div class="details" @click="$parent.openDetails(item)">
        <div class="price" v-if="item.for_sale_status === SaleStatus.WithPrice || item.price_must_contact === false" style="margin-bottom: 5px; font-size: 15px; font-weight: bold;">{{ formatPrice(item.price) }}</div>
        <div class="price" v-if="item.for_sale_status === SaleStatus.WithContact || item.price_must_contact" style="margin-bottom: 5px; font-size: 15px; font-weight: bold;">Contact seller for price</div>
        <div class="price" v-if="item.for_sale_status === SaleStatus.NotAvailable" style="margin-bottom: 5px; font-size: 15px; font-weight: bold;">For Lease</div>
        <div class="price" v-if="item.for_sale_status === SaleStatus.ConsignedToAuction" style="margin-bottom: 5px; font-size: 15px; font-weight: bold;">Consigned to Auction</div>
        <div class="lease-price" v-if="item.for_lease_status === SaleStatus.WithPrice" style="margin-top: -5px; margin-bottom: 5px; font-size: 12px; font-weight: bold; color: gray;">Lease for {{ formatPrice(item.lease_price) }}/year</div>
        <div class="lease-price" v-if="item.for_lease_status === SaleStatus.WithContact" style="margin-top: -5px; margin-bottom: 5px; font-size: 12px; font-weight: bold; color: gray;">Contact seller for lease price</div>
        <div class="lease-price" v-if="item.for_sale_status === SaleStatus.ConsignedToAuction" style="margin-top: -5px; margin-bottom: 5px; font-size: 12px; font-weight: bold; color: gray;">Starting bid: {{ formatPrice(item.price) }}</div>
        <div class="name"><strong>Name: </strong> {{item.horse_name}}</div>
        <div class="breed" v-if="item.breed.length > 0"><strong>Breed: </strong>{{item.breed[0]}}</div>
        <div class="discipline" v-if="item.discipline.length > 0"><strong>Discipline: </strong>{{item.discipline[0]}}</div>
        <div class="age"><strong>Age: </strong>{{item.age > 0 || item.age_unit !== 'UTERO' ? item.age : ''}}{{item.age_unit === 'UTERO' ? 'Utero' : item.age_unit === 'MONTH' ? ' months old' : ' years old'}}</div>
        <div class="location" v-if="item.address.hide === false"><strong>Location: </strong>{{item.address.address}}</div>
        <div class="location" v-if="item.address.hide === true"><strong>Location: </strong>{{item.address.city}}, {{item.address.state}}</div>
      </div>
    </div>
    <div v-if="items.length === 0">
      No result found!
    </div>
  </div>
</template>

<script>
import ASpin from 'ant-design-vue/lib/spin';
import { HeartOutlined, HeartFilled } from '@ant-design/icons-vue';
import 'ant-design-vue/lib/spin/style/index.css';
import $ from "jquery";
import { SaleStatus } from '@/enums/sale_status';

export default {
  components: {
    ASpin,
    HeartOutlined,
    HeartFilled
  },
  props: {
    loading: Boolean,
    items: Array
  },
  data() {
    return {
      formatter: new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      }),
      SaleStatus
    }
  },
  methods: {
    formatPrice(value) {
      return this.formatter.format(value);
    },
    toggleFavorite(item) {
      if (this.$store.state.user == null) {
        $('#loginModal').modal('show');
        $('#myTab a[href="#sign-in-content"]').tab('show')
        return;
      }
      this.$store.dispatch('toggleFavoriteHorse', item.id);
    },
    isFavorited(item) {
      return this.$store.state.favorite_horses.indexOf(item.id) > -1;
    }
  }
}
</script>

<style lang="scss" scoped>
  .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .item {
    display: flex;
    justify-content: flex-start;
    width: 48%;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    border: 1px solid #ddd;
    cursor: pointer;

    @media only screen and (max-width: 700px) {
      width: 100%;
      margin-right: 0;
    }
  }
</style>